import { AppBar, Box, IconButton, MenuItem, Toolbar, Typography } from "@mui/material";
import { BasicDataGrid } from "./components/basicDataGrid/BasicDataGrid";
import { DetailedDataCard } from "./components/detailedDataCard/DetailedDataCard";
import { PointsManager } from "./components/pointsManager/PointsManager";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { ScorePointsManager } from "./components/pointsManager/ScorePointsManager";
import { HpXpMaxInput } from "./components/pointsManager/HpXpMaxInput";
import { ResultsOverview } from "./components/resultsOverview/ResultsOverview";
import { PhaseEnd } from "./components/PhaseEnd/PhaseEnd";
import { PrizesScreen } from "./components/prizesScreen/PrizesScreen";

const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          input: {
            color: "white"
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          input: {
            color: "white"
          }
        }
      }
    }
  },
  palette: {
    medalBrown: {
      main: "#cd7f32",
      contrastText: '#000000',
    },
    medalSilver: {
      main: "#C0C0C0",
      contrastText: '#000000',
    },
    medalGold: {
      main: "#FFD700",
      contrastText: '#000000',
    },
    medalGreen: {
      main: "#48a068",
      contrastText: '#000000',
    },
    dead: {
      main: "#898989",
      contrastText: '#ffffff',
    },
    dangerRed: {
      main: "#ff3838",
      contrastText: '#fff',
    },
    scoreTopGreen: {
      main: "#466c59",
      contrastText: '#fff',
    },
    scorePodium: {
      main: "#ffcafa",
      contrastText: '#000',
    },
    scoreGreen: {
      main: "#adfea5",
      contrastText: '#414141',
    },
    scoreYellow: {
      main: "#fbff7e",
      contrastText: '#414141',
    },
    scoreRed: {
      main: "#f36868",
      contrastText: '#fff',
    },
    hpRed: {
      main: "#b23415",
      contrastText: '#fff',
    },
    xpBlue: {
      main: "#6495ed",
      contrastText: '#fff',
    },
    green: {
      main: '#3cb371',
      contrastText: '#fff',
    },
    blue: {
      main: '#6495ed',
      contrastText: '#fff',
    },
    grey: {
      main: '#708090',
      contrastText: '#fff',
    },
    black: {
      main: '#000000',
      contrastText: '#fff',
    },
    yellow: {
      main: '#f2f26f',
      contrastText: '#414141',
    },
    red: {
      main: '#cd5c5c',
      contrastText: '#fff',
    },
    white: {
      main: '#f9f9f9',
      contrastText: '#414141',
    },
    whiteInput: {
      main: '#f9f9f9',
      contrastText: '#f9f9f9',
      darker: '#f9f9f9'
    },
    brown: {
      main: '#f5ad98',
      contrastText: '#fff',
    },
    purple: {
      main: '#9b9cca',
      contrastText: '#fff',
    },
    pink: {
      main: '#ad9ab4',
      contrastText: '#fff',
    },
    orange: {
      main: '#da8a60',
      contrastText: '#fff',
    },
  }
});

const App = () => { //TODO To tsx
  const navigate = useNavigate();
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <AppBar position="static">
          <Toolbar variant="dense">
            <MenuItem onClick={() => navigate("")}>
                <Typography textAlign="center">RANKING</Typography>
            </MenuItem>
            <MenuItem onClick={() => navigate("roundByRank")}>
                <Typography textAlign="center">ROUND BY RANK</Typography>
            </MenuItem>
            <MenuItem onClick={() => navigate("roundByScore")}>
                <Typography textAlign="center">ROUND BY SCORE</Typography>
            </MenuItem>
            <MenuItem onClick={() => navigate("results")}>
                <Typography textAlign="center">RESULTS</Typography>
            </MenuItem>
            <MenuItem onClick={() => navigate("prizes")}>
                <Typography textAlign="center">PRIZES</Typography>
            </MenuItem>
            <MenuItem onClick={() => navigate("phaseEnd")}>
                <Typography textAlign="center">PHASE END</Typography>
            </MenuItem>
          </Toolbar>
        </AppBar>
        <Routes>
          <Route path="/" element={<BasicDataGrid />} />
          <Route path="roundByRank" element={<PointsManager />} />
          <Route path="roundByScore" element={<ScorePointsManager />} />
          <Route path="dataCard/:id" element={<DetailedDataCard />} />
          <Route path="results" element={<ResultsOverview />} />
          <Route path="phaseEnd" element={<PhaseEnd />} />
          <Route path="prizes" element={<PrizesScreen />} />
        </Routes>
      </ThemeProvider>
    </div>
  );
}

// <DetailedDataCard 
// id={0} /> 
// <BasicDataGrid />
// <PointsManager />

export default App;
