import React from 'react'
import { Medal } from './Medal';
import { Grid } from '@mui/material';

export const MedalsBox = (props) => {
	const {
		medals
	} = props;
    let medalsJSX;
    if(medals){
        medalsJSX = medals.map((item, i) => {
            return (
                <Grid item>
                    <Medal 
                        medal={item} />
                </Grid> 
            );
        })
    }
	return(
        <Grid 
            container
            justifyContent="center"
            alignItems="center"
            spacing={0.5}
            sx={{ padding: 3 }}
        >
            {medalsJSX}
		</Grid>
	);
}