import React, { useState } from 'react'
import { Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, ResponsiveContainer, LineChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Line } from 'recharts';
import { StatName } from '../../constants/stats';
import { TextField } from '@mui/material';
import { cloneDeep } from 'lodash';

export const ResultsChart = (props) => {
	const {
    results
	} = props.data;

  const [roundsInGraph, setRoundsInGraph] = useState(12);
  const newResults = cloneDeep(results);
  const newResultsHpXp = cloneDeep(results);
  for(let item of newResults){
    item.invertedScore = (100 - item.score).toFixed(3);
    item.roundsRank = item.roundRank;
    item.roundRank = item.roundRank * -1;
    item.rank = item.rank * -1;
  }
  for(let item of newResultsHpXp){
    item.hp = item.newHp;
    item.maxHp = item.newMaxHp;
    item.xp = item.newXp;
    item.level = item.newLevel;
  }
  newResultsHpXp.unshift({
    hp: 250,
    maxHp: 250,
    xp: 0,
    level: 1,
  });

	return(
    <>
      <TextField
					autoComplete='off'
					focused
					size="small"
					value={roundsInGraph}
					label={"Rounds in graph"}
					id="outlined-basic" 
					variant="outlined" 
					color="whiteInput"
					sx={{ width: 150}}
					onChange={(event) => {
						setRoundsInGraph(event.target.value);
					}}
			/>
      <div className="resultsChartContainer">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            width={1000}
            height={400}
            data={newResults.slice(-1 * roundsInGraph)}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis />
            <YAxis tickFormatter={(item) => item * -1} domain={['dataMin', 'dataMax']} allowDecimals={false} tickCount={7} minTickGap={0}  />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="roundRank" stroke="#82ca9d" strokeWidth="2" activeDot={{ r: 5 }}  />
          </LineChart>
        </ResponsiveContainer>
      </div>
      <div className="resultsChartContainer">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            width={1000}
            height={400}
            data={newResults.slice(-1 * roundsInGraph)}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis />
            <YAxis tickFormatter={(item) => item * -1} domain={['dataMin', 'dataMax']} tickCount={7} minTickGap={0}/>
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="rank" stroke="#f2f26f" strokeWidth="2" activeDot={{ r: 5 }} />
          </LineChart>
        </ResponsiveContainer>
      </div>
      <div className="resultsChartContainer">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            width={1000}
            height={400}
            data={newResults.slice(-1 * roundsInGraph)}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis />
            <YAxis domain={['dataMin', 'dataMax']} allowDecimals={false} tickCount={7} minTickGap={0} />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="score" stroke="#ad9ab4" strokeWidth="2" activeDot={{ r: 5 }} />
          </LineChart>
        </ResponsiveContainer>
      </div>
      <div className="resultsChartContainer">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            width={1000}
            height={400}
            data={newResultsHpXp.slice(-1 * roundsInGraph)}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis />
            <YAxis  />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="hp" stroke="#b23415" strokeWidth="2" activeDot={{ r: 5 }} />
            <Line type="monotone" dataKey="maxHp" stroke="#da8a60" strokeWidth="2" activeDot={{ r: 5 }} />
          </LineChart>
        </ResponsiveContainer>
      </div>
      <div className="resultsChartContainer">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            width={1000}
            height={400}
            data={newResultsHpXp.slice(-1 * roundsInGraph)}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis />
            <YAxis domain={['dataMin', 'dataMax']} allowDecimals={false} tickCount={7} minTickGap={0} />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="xp" stroke="#6495ed" strokeWidth="2" activeDot={{ r: 5 }} />
          </LineChart>
        </ResponsiveContainer>
      </div>
      <div className="resultsChartContainer">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            width={1000}
            height={400}
            data={newResultsHpXp.slice(-1 * roundsInGraph)}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis />
            <YAxis domain={['dataMin', 'dataMax']} tickCount={7} minTickGap={0}/>
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="level" stroke="#f9f9f9" strokeWidth="2" activeDot={{ r: 5 }} />
          </LineChart>
        </ResponsiveContainer>
      </div>
      <div className="resultsChartContainer">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            width={1000}
            height={400}
            data={newResults.slice(-1 * roundsInGraph)}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis />
            <YAxis tickFormatter={(item) => item * -1} domain={['dataMin', 'dataMax']} tickCount={7} minTickGap={0}/>
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="roundRank" stroke="#82ca9d" strokeWidth="2" activeDot={{ r: 5 }}  />
            <Line type="monotone" dataKey="rank" stroke="#f2f26f" strokeWidth="2" activeDot={{ r: 5 }} />
          </LineChart>
        </ResponsiveContainer>
      </div>
      <div className="resultsChartContainer">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            width={1000}
            height={400}
            data={newResults.slice(-1 * roundsInGraph)}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis />
            <YAxis minTickGap={0}/>
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="roundsRank" stroke="#82ca9d" strokeWidth="2" activeDot={{ r: 5 }}  />
            <Line type="monotone" dataKey="invertedScore" stroke="#ad9ab4" strokeWidth="2" activeDot={{ r: 5 }} />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </>
	);
}	