import React from 'react'
import { BasicDataItem } from './BasicDataItem';
import './BasicDataGrid.css';
import { CharacterAvatarBox } from '../characterAvatarBox/CharacterAvatarBox';
import { HpDisplay } from '../detailedDataCard/HpDisplay';
import { LevelDisplay } from '../detailedDataCard/LevelDisplay';
import { RankPosition } from './RankPosition';
import { MedalsBox } from '../medals/MedalsBox';

export const BasicDataRow = (props) => {
	const {
		data
	} = props;

	const rankDifference = data.lastRank - data.rank;
	return(
		<>
			<RankPosition
				rank={data.rank}
				rankDifference={rankDifference} />
			<CharacterAvatarBox 
				name={data.name}
				color={data.color}
				image={data.image}
				id={data.id}
			/>
			<HpDisplay
				maxHp = {data.maxHp}
				currentHp = {data.currentHp} />	
			<LevelDisplay
					level = {data.level} 
					xp = {data.xp}
					nextLevelXp = {data.nextLevelXp} />
			<MedalsBox
				medals={data.medals || []} />
			<BasicDataItem 
				value={data.attack} />
			<BasicDataItem 
				value={data.defense} />
			<BasicDataItem 
				value={data.speed} />
			<BasicDataItem 
				value={data.agility} />
			<BasicDataItem 
				value={data.precision} />
		</>
	);
}