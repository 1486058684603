import React from 'react'
import { useSelector } from 'react-redux';

export const MiniAvatar = (props) => {
	const {
		image, 
		name
	} = props;

    const metadata = useSelector(state => state.metadata);
	const imageSource = metadata.isAlternativeImages ?  `${process.env.PUBLIC_URL}/img/${name}H.jpg` : image;

	return(
		<div className="miniAvatarContainer">
			<img src={imageSource} className="miniAvatarImage"/>
		</div>
	);
}