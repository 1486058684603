import React from 'react'
import { CompactUserTag } from '../pointsManager/CompactUserTag';
import { Grid, Typography } from '@mui/material';
import { LevelDisplay } from '../detailedDataCard/LevelDisplay';
import { HpDisplay } from '../detailedDataCard/HpDisplay';
import { RankPosition } from '../basicDataGrid/RankPosition';

export const UserResultsCard = (props) => {
	const { data, key } = props;
    const lastResults = data.results[data.results.length - 1];

    return(
        lastResults && (<>
            <CompactUserTag
                key={`${key}cut`} 
                data={data} />
            <Grid 
                container 
                sx={{ padding: 1 }}
                justifyContent="center"
                alignItems="center"
            >
                <Grid item>
                    <Grid container>
                        <Typography textAlign="center" color="#ffffff" variant="subtitle1" sx={{ marginRight: 2, fontWeight: 600 }}>
                            {`Round rank:`}
                        </Typography>
                        <Typography textAlign="center" color="#ffffff" variant="subtitle1" sx={{ marginRight: 5 }}>
                            {`${lastResults.roundRank}°`}
                        </Typography>
                    </Grid>
                    <Grid container>
                        <Typography textAlign="center" color="#ffffff" variant="subtitle1" sx={{ marginRight: 2, fontWeight: 600 }}>
                            {`Score:`}
                        </Typography>
                        <Typography textAlign="center" color="#ffffff" variant="subtitle1" sx={{ marginRight: 5 }}>
                            {`${lastResults.score ? lastResults.score : "-"}`}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container>
                        <Typography textAlign="center" color="#ffffff" variant="subtitle1" sx={{ marginRight: 2, fontWeight: 600 }}>
                            {`Prev. Rank:`}
                        </Typography>
                        <Typography textAlign="center" color="#ffffff" variant="subtitle1" sx={{ marginRight: 5 }}>
                            {`${lastResults.lastRank === 1500 ? "-" : lastResults.lastRank}°`}
                        </Typography>
                    </Grid>
                    <Grid container>
                        <Typography textAlign="center" color="#ffffff" variant="subtitle1" sx={{ marginRight: 2, fontWeight: 600 }}>
                            {`Rank:`}
                        </Typography>
                        <RankPosition
                            isSmall
                            rank={lastResults.rank}
                            rankDifference={lastResults.lastRank - lastResults.rank}
                        />
                    </Grid>
                </Grid>
                <Grid item>
                    <LevelDisplay
                        level = {lastResults.previousLevel} 
                        xp = {lastResults.previousLevelXp}
                        nextLevelXp = {lastResults.previousNextLevelXp} 
                    />
                    <Grid container justifyContent="center" alignItems="center" sx={{ marginTop: 1 }}>
                        <Typography textAlign="center" color="#ffffff" variant="h5" sx={{ marginRight: 1, marginLeft: 2 }}>
                            {`⇩`}
                        </Typography>
                        <Typography textAlign="center" color="#ffffff" variant="subtitle1" sx={{ marginRight: 2 }}>
                            {`+ ${lastResults.newXp - lastResults.previousXp}`}
                        </Typography>
                    </Grid>
                    <LevelDisplay
                        level = {lastResults.newLevel} 
                        xp = {lastResults.newLevelXp}
                        nextLevelXp = {lastResults.newNextLevelXp} 
                    />
                </Grid>
                <Typography textAlign="center" color="#ffffff" variant="h4" sx={{ marginRight: 4, marginLeft: 4 }} />
                <Grid item>
                    <HpDisplay
                        currentHp = {lastResults.previousHp}
                        maxHp = {lastResults.previousMaxHp} 
                    />		
                    <Grid container justifyContent="center" alignItems="center" sx={{ marginTop: 1 }}>
                        <Typography textAlign="center" color="#ffffff" variant="h5" sx={{ marginRight: 1, marginLeft: 2 }}>
                            {`⇩`}
                        </Typography>
                        <Typography textAlign="center" color="#ffffff" variant="subtitle1" sx={{ marginRight: 2 }}>
                            {`${lastResults.newHp - lastResults.previousHp > 0 ? "+" : "-"} ${Math.abs(lastResults.newHp - lastResults.previousHp)}`}
                        </Typography>
                    </Grid>
                    <HpDisplay
                        currentHp = {lastResults.newHp}
                        maxHp = {lastResults.newMaxHp} 
                    />		
                </Grid>
            </Grid>
        </>)
    );
}	