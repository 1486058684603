import React from 'react'

export const StatProgressBar = (props) => {
	const {
    value,
		max,
		color
	} = props;

	return(
		<div className="xpBarContainer">
			<progress 
				className={`xpBar xpBar__${color}`}
				max={max} 
				value={value} />
			<div className="xpBarText">
				<div className="xpBarTextValue">
					{value}
				</div>
				<div className="xpBarTextMax">
					{max}
				</div>
				
			</div>
    </div>
	);
}	