import { Grid, Typography } from '@mui/material';
import React from 'react'

export const RankPosition = (props) => {
	const {
    	rank,
		rankDifference,
		isSmall
	} = props;

	let rankDifferenceTag = "-";
	let rankDifferenceColor = "#FFFFFF";

	if(rankDifference < 999 && rankDifference > 0){
		rankDifferenceTag = `↑ ${rankDifference}`;
		rankDifferenceColor = "#5bf91e";
	}else if(rankDifference < 0){
		rankDifferenceTag = `↓ ${Math.abs(rankDifference)}`;
		rankDifferenceColor = "#ff4d4d";
	}

	return(
		<div>
			<Grid 
				container
				justifyContent="center"
				alignItems="center"
				spacing={1.25}
				direction="row-reverse"
			>
				<Grid item xs={5}>
					<Typography textAlign="center" color="#ffffff" variant={isSmall ? "subtitle1" : "h6"}>{`${rank}°`}</Typography>
				</Grid>
				<Grid item xs={7}>
					<Typography textAlign="center" color={rankDifferenceColor} variant={isSmall ? "subtitle2" : "subtitle2"}>{`${rankDifferenceTag}`}</Typography>
				</Grid>
			</Grid>
		</div>
	);
}