import { Avatar, Chip } from '@mui/material';
import React from 'react'
import { MiniAvatar } from '../characterAvatarBox/MiniAvatar';
import { useSelector } from 'react-redux';

export const CompactUserTag = (props) => {
  const {
    data
  } = props;

  const metadata = useSelector(state => state.metadata);

  const isInDanger = data.name !== "-" && data.currentHp <= metadata.maxHpLoss;
  const isDead = data.name !== "-" && data.currentHp <= 0;
  let color = data.color;
  if(isDead){
    color = "scoreGreen";
  }else if(isInDanger){
    color = "dangerRed";
  }
  
	return(
    <Chip
      sx={{ width: 200, height:50, borderRadius:11, fontSize:18 }}
      avatar={<MiniAvatar
				image={data.image}
        name={data.name}
			/>}
      variant={isInDanger ? "outlined" : "filled"}
      label={data.name}
      color={color}
    />
	);
}	
