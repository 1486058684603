import React from 'react'
import { Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, ResponsiveContainer } from 'recharts';
import { StatName } from '../../constants/stats';

export const StatsChart = (props) => {
	const {
    data
	} = props;

  const parsedData = [
    {
      "name" : StatName.ATTACK,
      "value" : data.attack
    },
    {
      "name" : StatName.DEFENSE,
      "value" : data.defense
    },
    {
      "name" : StatName.AGILITY,
      "value" : data.agility
    },
    {
      "name" : StatName.SPEED,
      "value" : data.speed
    },
    {
      "name" : StatName.PRECISION,
      "value" : data.precision
    },
    {
      "name" : StatName.HP,
      "value" : (data.maxHp - 210) / 5
    },
  ];

	return(
    <div className="statsChartContainer">
      <ResponsiveContainer width="100%" height="100%">
        <RadarChart cx="50%" cy="50%" outerRadius="80%" data={parsedData}>
          <PolarGrid />
          <PolarAngleAxis dataKey="name" />
          <PolarRadiusAxis />
          <Radar name="Stats" dataKey="value" stroke="#8884d8" fill="#8884d8" fillOpacity={0.6} />
        </RadarChart>
      </ResponsiveContainer>
    </div>
	);
}	