import React from 'react'

export const StatListItem = (props) => {
	const {
    name,
    value
	} = props;

	return(
		<div className="statListItemContainer">
			<div  className="statListItemName">
				{`${name.toUpperCase()}`}
			</div>
			<div  className="statListItemValue">
				{value}
			</div>
    </div>
	);
}	