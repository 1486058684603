import { ClassName } from "../constants/classes";

/*
  T0 - 4.8
  T1 - 6
  T2 - 7.1
*/

export const getBaseStatsForLevel = (level, className) => {
  switch(className) {
    case ClassName.NOVICE : {
      return getNovice(level);
    }
    case ClassName.COMBATANT : {
      return getCombatant(level);
    }
    case ClassName.DEFENDER : {
      return getDefender(level);
    }
    case ClassName.ATTACKER : {
      return getAttacker(level);
    }
    case ClassName.FIGHTER : {
      return getFighter(level);
    }
    case ClassName.RUNNER : {
      return getRunner(level);
    }
    case ClassName.BRUTE : {
      return getBrute(level);
    }
    case ClassName.SLINGER : {
      return getSlinger(level);
    }
    case ClassName.ARMOR : {
      return getArmor(level);
    }
    case ClassName.DODGER : {
      return getDodger(level);
    }
  }
  
}

const getBasicStatValue = (level, multiplier) => {
  return Math.floor((level + 10) * multiplier);
}

const getMaxHpValue = (level, baseMultiplier) => {
  // return Math.floor((level + 10) * baseMultiplier * 3);
  return Math.floor((level + 49) * baseMultiplier * 5);
}

const getNovice = (level) => {
  return {
    attack: getBasicStatValue(level, 0.8),
    defense: getBasicStatValue(level, 0.8),
    agility: getBasicStatValue(level, 0.8),
    speed: getBasicStatValue(level, 0.8),
    precision: getBasicStatValue(level, 0.8),
    // maxHp: getMaxHpValue(level, 0.8),
    maxHp: getMaxHpValue(level, 1),
  };
}
  
const getCombatant = (level) => {
  return {
    attack: getBasicStatValue(level, 1),
    defense: getBasicStatValue(level, 1),
    agility: getBasicStatValue(level, 1),
    speed: getBasicStatValue(level, 1),
    precision: getBasicStatValue(level, 1),
    maxHp: getMaxHpValue(level, 1),
  };
}

const getDefender = (level) => {
  return {
    attack: getBasicStatValue(level, 0.9),
    defense: getBasicStatValue(level, 1.3),
    agility: getBasicStatValue(level, 0.9),
    speed: getBasicStatValue(level, 0.9),
    precision: getBasicStatValue(level, 0.9),
    maxHp: getMaxHpValue(level, 1.1),
  };
}

const getAttacker = (level) => {
  return {
    attack: getBasicStatValue(level, 1.3),
    defense: getBasicStatValue(level, 0.9),
    agility: getBasicStatValue(level, 1),
    speed: getBasicStatValue(level, 0.95),
    precision: getBasicStatValue(level, 0.95),
    maxHp: getMaxHpValue(level, 0.9),
  };
}

const getFighter = (level) => {
  return {
    attack: getBasicStatValue(level, 1.25),
    defense: getBasicStatValue(level, 1.25),
    agility: getBasicStatValue(level, 1.15),
    speed: getBasicStatValue(level, 1.15),
    precision: getBasicStatValue(level, 1.15),
    maxHp: getMaxHpValue(level, 1.15),
  };
}

const getRunner = (level) => {
  return {
    attack: getBasicStatValue(level, 1.05),
    defense: getBasicStatValue(level, 1.05),
    agility: getBasicStatValue(level, 1.25),
    speed: getBasicStatValue(level, 1.25),
    precision: getBasicStatValue(level, 1.25),
    maxHp: getMaxHpValue(level, 1.25),
  };
}

const getBrute = (level) => {
  return {
    attack: getBasicStatValue(level, 1.75),
    defense: getBasicStatValue(level, 0.9),
    agility: getBasicStatValue(level, 1.15),
    speed: getBasicStatValue(level, 1.15),
    precision: getBasicStatValue(level, 1.15),
    maxHp: getMaxHpValue(level, 1),
  };
}

const getSlinger = (level) => {
  return {
    attack: getBasicStatValue(level, 1.5),
    defense: getBasicStatValue(level, 1),
    agility: getBasicStatValue(level, 1.15),
    speed: getBasicStatValue(level, 1.15),
    precision: getBasicStatValue(level, 1.3),
    maxHp: getMaxHpValue(level, 1),
  };
}

const getArmor = (level) => {
  return {
    attack: getBasicStatValue(level, 0.8),
    defense: getBasicStatValue(level, 1.6),
    agility: getBasicStatValue(level, 1.1),
    speed: getBasicStatValue(level, 1.1),
    precision: getBasicStatValue(level, 1.1),
    maxHp: getMaxHpValue(level, 1.4),
  };
}

const getDodger = (level) => {
  return {
    attack: getBasicStatValue(level, 0.9),
    defense: getBasicStatValue(level, 1.4),
    agility: getBasicStatValue(level, 1.3),
    speed: getBasicStatValue(level, 1.1),
    precision: getBasicStatValue(level, 1.1),
    maxHp: getMaxHpValue(level, 1.3),
  };
}