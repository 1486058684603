import { EclAccordion, EclBanner, EclButton, EclDateBlock } from '@ecl/ecl-webcomponents-react';
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { selectUserData, setTeam } from '../../slices/basicStatsSlice';
import { CharacterAvatarBox } from '../characterAvatarBox/CharacterAvatarBox';
import './DetailedDataCard.css';
import { HpDisplay } from './HpDisplay';
import { LevelDisplay } from './LevelDisplay';
import { StatList } from './StatList';
import { StatPointDistributionCard } from './StatPointDistributionCard';
import { StatsChart } from './StatsChart';
import { useNavigate, useParams } from 'react-router-dom';
import { TextField } from '@mui/material';
import { MedalsBox } from '../medals/MedalsBox';
import { ResultsChart } from './ResultsChart';

export const DetailedDataCard = (props) => {
	const {
    	id
	} = useParams();
	
	const data = useSelector(selectUserData(parseInt(id)));
	const dispatch = useDispatch();
	
	return(
		data && (
			<div className="detailedDataCardContainer">
				<div className="detailedDataCardAvatarLevel">
					<EclButton
						type='primary'>
					</EclButton>
					<EclDateBlock />
					<EclBanner />
					<CharacterAvatarBox 
						name = {data.name}
						color = {data.color}
						image = {data.image}
					/>
					<LevelDisplay
						level = {data.level} 
						xp = {data.xp}
						nextLevelXp = {data.nextLevelXp} />
					<HpDisplay
						maxHp = {data.maxHp}
						currentHp = {data.currentHp} />		
					<TextField
						autoComplete='off'
						focused
						value={data.team}
						id="outlined-basic" 
						label={"Team"}
						variant="outlined" 
						color="whiteInput"
						sx={{ width: 80, height:65, marginTop: 3}}
						onChange={(event) => {
							dispatch(setTeam({
								id,
								value: event.target.value
							}));
						}}
						/>
					<MedalsBox
						medals={data.medals || []} />
				</div>
				<div className="statsWithChartContainer">
					<StatList
						data = {data} />
					<StatsChart
						data = {data} />
					<ResultsChart
						data = {data} />
				</div>
				<StatPointDistributionCard 
					data = {data}/>
		</div>
		)
	);
}	