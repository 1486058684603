import React from 'react'

export const BasicDataItem = (props) => {
	const {
    	value
	} = props;

	return(
		<div className="basicDataItem">
      {value}
    </div>
	);
}