import React from 'react'
import './CharacterAvatarBox.css';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

export const CharacterAvatarBox = (props) => {
	const {
		color,
		name,
		image,
		id
	} = props;

	const navigate = useNavigate();
    const metadata = useSelector(state => state.metadata);

	const imageSource = metadata.isAlternativeImages ?  `${process.env.PUBLIC_URL}/img/${name}H.jpg` : image;
	return(
		<div 
			className={`imageBox imageBox__${color}`} 
			onClick={() => {
				if(id !== undefined){
					navigate(`dataCard/${id}`);
				}
			}}
		>
			<img src={imageSource} className="characterBoxImage"/>
			<div className={`nameBox nameBox__${color}`}>
					{name}
			</div>
		</div>
	);
}