import React from 'react'
import { SortingDirection } from './BasicDataGrid';

export const SortableDataHeader = (props) => {
	const {
    value,
		onClick,
		sortingDirection
	} = props;
	
	let sortingDirectionSymbol = ""
	switch(sortingDirection){
		case SortingDirection.NONE: {
			sortingDirectionSymbol = "-"
			break;
		}
		case SortingDirection.ASCENDING: {
			sortingDirectionSymbol = "↑"
			break;
		}
		case SortingDirection.DESCENDING: {
			sortingDirectionSymbol = "↓"
			break;
		}
		default: {
			sortingDirectionSymbol = ""
		}
	}

	return(
		<div className="basicDataHeader" onClick={onClick}>
      {`${value} ${sortingDirectionSymbol}`}
    </div>
	);
}