import React from 'react'

export const StatPointDistributionItem = (props) => {
	const {
    name,
		value,
		pointsSpent,
		pointsForNext,
		pointsOfPrevious,
		onPlusClicked,
		onMinusClicked,
	} = props;

	return(
		<div className="statPointDistributionItemContainer">
			<div className="statPointDistributionItemName">
				{name}
			</div>
			<div className="statPointDistributionItemBottomRow">
				<div className="statPointDistributionItemSignColumn">
					<div className="statPointDistributionItemSign" onClick={onMinusClicked}>
						-
					</div>
					<div className="statPointDistributionItemPointCost">
						{pointsOfPrevious}
					</div>
				</div>
				<div className="statPointDistributionItemValue">
					{value}
				</div>
				<div className="statPointDistributionItemValuePointsSpent">
					{`(${pointsSpent})`}
				</div>
				<div className="statPointDistributionItemSignColumn">
					<div className="statPointDistributionItemSign" onClick={onPlusClicked}>
						+
					</div>
					<div className="statPointDistributionItemPointCost">
						{pointsForNext}
					</div>
				</div>
			</div>
		</div>
	);
}	