import { Chip } from '@mui/material';
import React from 'react'

export const Medal = (props) => {
	const {
		medal
	} = props;


	return(
        <Chip
            label={medal.symbol}
            color={medal.color}
            size="small"
            sx={{ height: 15, width: 15, fontSize:10, paddingRight: 0.6, textAlign: "center" }}
      />
	);
}