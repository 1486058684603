import React from 'react'
import { StatListItem } from './StatListItem';
import { StatsChart } from './StatsChart';

export const StatList = (props) => {
	const {
    data
	} = props;

  const calculateHitRate = (precision) => { //TODO Util functions
    return ((1000 * precision) / (70 + 10*precision)).toFixed(2); /* 1000x/70+10x */
  }

  const calculateCriticalHitRate = (precision, agility) => { //TODO Util functions
    return ((1000 * (precision/3 + agility)) / (20000 + 10*(precision/3 + agility))).toFixed(2); /* 1000x/20000+10x */
  }
  
  const calculateXpBonus = () => { 
    let xpBonus = 0;
    if(data.medals){
      for(let medal of data.medals){
        if(medal.type === "xpBoost"){ //TODO Add to constants
          xpBonus += parseFloat(medal.value);
        }
      }
    }
    return xpBonus.toFixed(2);
  }

  const calculateHpBonus = () => { 
    let hpBonus = 0;
    if(data.medals){
      for(let medal of data.medals){
        if(medal.type === "hpBoost"){ //TODO Add to constants
          hpBonus += parseFloat(medal.value);
        }
      }
    }
    return hpBonus.toFixed(2);
  }

  const calculateExtraHp = () => { 
    const hpBonus = calculateHpBonus() / 100;
    return ((data.level - 1) * 5 * hpBonus).toFixed(2);
  }

  const calculateAverageRank = () => { 
    let rankSum = 0;
    for(let item of data.results){
      rankSum += item.roundRank;
    }
    return (rankSum / data.results.length).toFixed(2);
  }

  const calculateAverageScore = () => { 
    let scoreSum = 0;
    for(let item of data.results){
      scoreSum += item.score;
    }
    return (scoreSum / data.results.length).toFixed(2);
  }

  const calculateAORank = (games) => { 
    let rankSum = 0;
    for(let item of data.results.slice(games * -1)){
      rankSum += item.roundRank;
    }
    return (rankSum / data.results.slice(games * -1).length).toFixed(2);
  }

  const calculateAOScore = (games) => { 
    let scoreSum = 0;
    for(let item of data.results.slice(games * -1)){
      scoreSum += item.score;
    }
    return (scoreSum / data.results.slice(games * -1).length).toFixed(2);
  }

	return(
		<div className="statListContainer">
      <StatListItem 
				name="attack"
			 	value={data.attack}/>
      <StatListItem 
        name="defense"
        value={data.defense}/>
      <StatListItem 
        name="agility"
        value={data.agility}/>
      <StatListItem 
        name="speed"
        value={data.speed}/>
      <StatListItem 
        name="precision"
        value={data.precision}/>
      <StatListItem 
        name="xp boost"
        value={`${calculateXpBonus()}%`}/>
      <StatListItem 
        name="total xp"
        value={Math.floor(data.totalXp)}/>
      <StatListItem 
        name="bonus xp"
        value={(data.xpFromBonus || 0).toFixed(2)}/>
      <StatListItem 
        name="bonus xp (%)"
        value={`${(100 * (data.xpFromBonus || 0) / data.totalXp).toFixed(2)}%`}/>
      <StatListItem 
        name="hp boost"
        value={`${calculateHpBonus()}%`}/>
      <StatListItem 
        name="extra hp"
        value={calculateExtraHp()}/>
      <StatListItem 
        name="medal score"
        value={data.medalScore || 0}/>
      <StatListItem 
        name="Avg. rank"
        value={`${calculateAverageRank()}°`}/>
      <StatListItem 
        name="ao. 5 rank"
        value={`${calculateAORank(5)}°`}/>
      <StatListItem 
        name="ao. 12 rank"
        value={`${calculateAORank(12)}°`}/>
      <StatListItem 
        name="Avg. score"
        value={calculateAverageScore()}/>
      <StatListItem 
        name="ao. 5 score"
        value={calculateAOScore(5)}/>
      <StatListItem 
        name="ao. 12 score"
        value={calculateAOScore(12)}/>
    </div>
	);
}	