import { createSlice } from '@reduxjs/toolkit'
import { ClassName } from '../constants/classes';
import { getBaseStatsForLevel } from '../util/getBaseStatsForLevel';
import basicStatsInitialData from './basicStatsInitialData.json';
import { clone, cloneDeep } from 'lodash';

export const basicStatsSlice = createSlice({
  name: 'basicStats',
  initialState: {
    basicStats: basicStatsInitialData,
    metadata: {
      maxHpLoss: 50,
      maxXpGain: 25
    },
    eliminations: []
  },
  reducers: {
    updateStats: (state, action) => {
      const index = findIndexWithId(state.basicStats, action.payload);
      if(index !== -1){
        const item = state.basicStats[index];
        const baseStats = getBaseStatsForLevel(state.basicStats[index].level, state.basicStats[index].class);
        const statPoints = state.basicStats[index].statPoints;
        item.attack = baseStats.attack + statPoints.attack;
        item.defense = baseStats.defense + statPoints.defense;
        item.agility = baseStats.agility + statPoints.agility;
        item.speed = baseStats.speed + statPoints.speed;
        item.precision = baseStats.precision + statPoints.precision;
        item.hpRegeneration = Math.floor((baseStats.maxHp + statPoints.hp) / 20);
        
        let hpBonus = 1;
        let medalScore = 0;
        if(item.medals){
          for(let medal of item.medals){
            switch(medal.color){
              case "medalGreen": {
                medalScore += 15;
                break;
              }
              case "medalGold": {
                medalScore += 7;
                break;
              }
              case "medalSilver": {
                medalScore += 3;
                break;
              }
              case "medalBrown": {
                medalScore += 1;
                break;
              }
            }
            if(medal.type === "hpBoost"){ //TODO Add to constants
              hpBonus += medal.value/100;
            }
          }
        }
        item.medalScore = medalScore;

        const resultsIndex = item.results.length - 1;
        const previousBaseHp = item.results[resultsIndex].previousMaxHp;
        const newBaseHp = Math.floor(250 + (item.level - 1) * 5 * hpBonus);
        item.currentHp += newBaseHp - previousBaseHp;
        item.maxHp = newBaseHp;
        item.results[resultsIndex].newHp = item.currentHp;
        item.results[resultsIndex].newMaxHp = item.maxHp;  
      }
    },
    addStatPointAttack: (state, action) => {
      const index = findIndexWithId(state.basicStats, action.payload.id);
      if(index !== -1){
        state.basicStats[index].statPoints.attack += action.payload.amount;
      }
    },
    addStatPointDefense: (state, action) => {
      const index = findIndexWithId(state.basicStats, action.payload.id);
      if(index !== -1){
        state.basicStats[index].statPoints.defense += action.payload.amount;
      }
    },
    addStatPointAgility: (state, action) => {
      const index = findIndexWithId(state.basicStats, action.payload.id);
      if(index !== -1){
        state.basicStats[index].statPoints.agility += action.payload.amount;
      }
    },
    addStatPointSpeed: (state, action) => {
      const index = findIndexWithId(state.basicStats, action.payload.id);
      if(index !== -1){
        state.basicStats[index].statPoints.speed += action.payload.amount;
      }
    },
    addStatPointPrecision: (state, action) => {
      const index = findIndexWithId(state.basicStats, action.payload.id);
      if(index !== -1){
        state.basicStats[index].statPoints.precision += action.payload.amount;
      }
    },
    addStatPointHp: (state, action) => {
      const index = findIndexWithId(state.basicStats, action.payload.id);
      if(index !== -1){
        state.basicStats[index].statPoints.hp += action.payload.amount;
      }
    },
    addResultsSheet: (state, action) => {
      const index = findIndexWithId(state.basicStats, action.payload.id);
      if(index !== -1){
        state.basicStats[index].lastRoundRank = action.payload.roundRank;
        state.basicStats[index].results.push(action.payload.data);
      }
    },
    addHp: (state, action) => {
      const index = findIndexWithId(state.basicStats, action.payload.id);
      const item = state.basicStats[index];
      if(index !== -1){
        item.currentHp += action.payload.amount;

        const resultsIndex = item.results.length - 1;
        item.results[resultsIndex].newLevel = item.level;
        item.results[resultsIndex].newXp = Math.floor(item.totalXp);
        item.results[resultsIndex].newLevelXp = Math.floor(item.xp);
        item.results[resultsIndex].newNextLevelXp = item.nextLevelXp;
      }
    },
    addXp: (state, action) => {
      const index = findIndexWithId(state.basicStats, action.payload.id);
      const item = state.basicStats[index];
      if(index !== -1){
        let xpBonus = 1;
        if(item.medals){
          for(let medal of item.medals){
            if(medal.type === "xpBoost"){ //TODO Add to constants
              xpBonus += medal.value/100;
            }
          }
        }
        console.log(xpBonus)
        item.xp += action.payload.amount * xpBonus;
        item.totalXp += action.payload.amount * xpBonus;

        if(!item.xpFromBonus){
          item.xpFromBonus = 0;
        }
        item.xpFromBonus += action.payload.amount * (xpBonus - 1);

        while(item.xp >= item.nextLevelXp){
          item.xp -= item.nextLevelXp;
          item.level++;
          item.nextLevelXp = Math.floor(item.level ** 1.22 + 9)
        }
      }
    },
    setTeam: (state, action) => {
      const index = findIndexWithId(state.basicStats, action.payload.id);
      if(index !== -1){
        state.basicStats[index].team = action.payload.value;
      }
    },
    setIsAlternativeImages: (state, action) => {
      state.metadata.isAlternativeImages = action.payload;
    },
    setMaxHpLoss: (state, action) => {
      state.metadata.maxHpLoss = parseInt(action.payload || "0");
    },
    setMaxXpGain: (state, action) => {
      state.metadata.maxXpGain = parseInt(action.payload || "0");
    },
    setGreenMedalTop: (state, action) => {
      state.metadata.greenMedalTop = action.payload || "0";
    },
    setGreenMedalValue: (state, action) => {
      state.metadata.greenMedalValue = action.payload || "0";
    },
    setGoldMedalTop: (state, action) => {
      state.metadata.goldMedalTop = action.payload || "0";
    },
    setGoldMedalValue: (state, action) => {
      state.metadata.goldMedalValue = action.payload || "0";
    },
    setSilverMedalTop: (state, action) => {
      state.metadata.silverMedalTop = action.payload || "0";
    },
    setSilverMedalValue: (state, action) => {
      state.metadata.silverMedalValue = action.payload || "0";
    },
    setBronzeMedalTop: (state, action) => {
      state.metadata.bronzeMedalTop = action.payload || "0";
    },
    setBronzeMedalValue: (state, action) => {
      state.metadata.bronzeMedalValue = action.payload || "0";
    },
    setMedalType: (state, action) => {
      state.metadata.medalType = action.payload;
    },
    setRankPositions: (state) => {
      const rankingData = cloneDeep(state.basicStats);      

      rankingData.sort((a,b) => {
        if(b.currentHp - a.currentHp === 0){
          return b.totalXp - a.totalXp;
        }
        return b.currentHp - a.currentHp
      });
  
      let tiedRank = 1;
      for(let i = 0; i < rankingData.length; i++){
        const index = findIndexWithId(state.basicStats, rankingData[i].id);
        const item = state.basicStats[index];
        const resultsIndex = item.results.length - 1;

        if(i === 0){
          item.lastRank = state.basicStats[index].rank;
          item.rank = 1;   
          item.results[resultsIndex].lastRank = item.lastRank;
          item.results[resultsIndex].rank = item.rank; 
          continue;
        }
        if(rankingData[i].currentHp === rankingData[i-1].currentHp && rankingData[i].totalXp === rankingData[i-1].totalXp){
          item.lastRank = state.basicStats[index].rank;
          item.rank = tiedRank;   
          item.results[resultsIndex].lastRank = item.lastRank;
          item.results[resultsIndex].rank = item.rank;  
          continue;
        }
        item.lastRank = state.basicStats[index].rank;
        item.rank = i+1;    
        item.results[resultsIndex].lastRank = item.lastRank;
        item.results[resultsIndex].rank = item.rank; 

        tiedRank = i+1;
        if(item.currentHp <= 0){
          state.eliminations.push(cloneDeep(item));
          state.basicStats.splice(index, 1);
        }
      }
    },
    addMedal: (state, action) => {
      const index = findIndexWithId(state.basicStats, action.payload.id);
      const metadata = state.metadata;
      if(index !== -1){
        const item = state.basicStats[index];
        const rank = action.payload.rank;
        let value = 0;
        let color = "";
        if(!item.medals){
          item.medals = [];
        }
        if(rank <= parseFloat(metadata.greenMedalTop)){
          value = metadata.greenMedalValue;
          color = "medalGreen";
        }else if(rank <= parseFloat(metadata.goldMedalTop)){
          value = metadata.goldMedalValue;
          color = "medalGold";
        }else if(rank <= parseFloat(metadata.silverMedalTop)){
          value = metadata.silverMedalValue;
          color = "medalSilver";
        }else if(rank <= parseFloat(metadata.bronzeMedalTop)){
          value = metadata.bronzeMedalValue;
          color = "medalBrown";
        }
        if(value > 0){
          item.medals.push({
            "type": metadata.medalType, 
            "value": value,
            "color": color,
            "symbol": metadata.medalType.charAt(0)
          });
        }
      }
    },
    healAll: (state) => {
      try{
      const ely = state.basicStats[findIndexWithId(state.basicStats, 0)];
      const rag = state.basicStats[findIndexWithId(state.basicStats, 144)];

      rag.currentHp = rag.maxHp;
      }catch(e){
        alert(e)
      }
      // for(let item of state.basicStats){
      //   item.currentHp = item.maxHp;
      // }
    }
  }
})

const findIndexWithId = (state, id) => {
  return state.findIndex((item) => parseInt(item.id) === parseInt(id));
}

export const selectUserData = (playerId) => (state) => {
  return state.basicStats.find((item) => item.id === playerId);
};

export const { 
  updateStats,
  addStatPointAttack, 
  addStatPointDefense, 
  addStatPointAgility, 
  addStatPointSpeed, 
  addStatPointPrecision, 
  addStatPointHp, 
  addHp,
  addXp,
  setMaxHpLoss,
  setMaxXpGain,
  setGreenMedalTop,
  setGreenMedalValue,
  setGoldMedalTop,
  setGoldMedalValue,
  setSilverMedalTop,
  setSilverMedalValue,
  setBronzeMedalTop,
  setBronzeMedalValue,
  setMedalType,
  setRankPositions,
  addResultsSheet,
  setTeam,
  healAll,
  setIsAlternativeImages,
  addMedal
} = basicStatsSlice.actions;

export default basicStatsSlice.reducer;