import { Grid, TextField, Typography  } from '@mui/material';
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setMaxHpLoss, setMaxXpGain } from '../../slices/basicStatsSlice';

export const HpXpMaxInput = (props) => {
	const metadata = useSelector(state => state.metadata);
	const dispatch = useDispatch();
	const maxHpLoss = metadata.maxHpLoss;
	const maxXpGain = metadata.maxXpGain;

	return(
		<>
			<Grid item>
				<TextField
					autoComplete='off'
					focused
					size="small"
					value={maxHpLoss}
					label={"Max HP loss"}
					id="outlined-basic" 
					variant="outlined" 
					color="whiteInput"
					sx={{ width: 100}}
					onChange={(event) => {
						dispatch(setMaxHpLoss(event.target.value));
					}}
				/>
			</Grid>
			<Grid item>
				<TextField
					autoComplete='off'
					focused
					size="small"
					value={maxXpGain}
					label={"Max XP gain"}
					id="outlined-basic" 
					variant="outlined" 
					color="whiteInput"
					sx={{ width: 100}}
					onChange={(event) => {
						dispatch(setMaxXpGain(event.target.value));
					}}
				/>
			</Grid>
		</>
	);
}	
