export const ClassName = {
  NOVICE: "novice",         // T0 = Base

  DEFENDER: "defender",     // T1 - Def
  ATTACKER: "attacker",     // T1 - Atk
  COMBATANT: "combatant",   // T1 - Balanced

  BRUTE: "brute",           // T2 - Pure Atk
  SLINGER: "slinger",       // T2 - Atk/Pre
  ARMOR: "armor",           // T2 - Pure Def
  DODGER: "dodger",         // T2 - Def/Agi
  FIGHTER: "fighter",       // T2 - Atk/Def semibalanced
  RUNNER: "runner"           // T2 - Agi/Spd/Pre
}