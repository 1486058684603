import { Fab } from '@mui/material';
import React from 'react'
import AddIcon from '@mui/icons-material/Add';

export const AddButton = (props) => {
	const {
		id,
		index,
		onClick
	} = props;

	return(
		<Fab key={`${id}fab`} size="medium" color="primary" aria-label="add" onClick={() => onClick(id, index)}>
			<AddIcon />
		</Fab>
	);
}	
