import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { CompactUserTag } from './CompactUserTag';
import { Button, Grid } from '@mui/material';
import { cloneDeep, set } from 'lodash';
import { addXp, addHp, setRankPositions, addResultsSheet, updateStats, addMedal } from '../../slices/basicStatsSlice';
import { useNavigate } from 'react-router-dom';
import { AddToRankScore } from './AddToRankScore';
import { ScoreUserTag } from './ScoreUserTag';

const generateStartingFinalData = (data) => {
  let finalData = [];
  for(let i = 0; i < data.length; i++){
    finalData.push({
      name: "-",
      color: "grey"
    });
  } 
  return finalData;
}

let inputData;
let hpLostData;
let xpGainData;
let finalDataCount;

export const ScorePointsManager = (props) => {
	const data = useSelector(state => state.basicStats);
  const metadata = useSelector(state => state.metadata);
  const [shuffledData, setShuffledData] = useState(shuffle(cloneDeep(data)));
  const [finalData, setFinalData] = useState(generateStartingFinalData(data));
  const [flag, setFlag] = useState(1);
	const dispatch = useDispatch();
	const navigate = useNavigate();

  useEffect(() => {
    inputData = {};
    finalDataCount = 0;
    generateHpAndXpGainsData();
  },[]);

  const generateHpAndXpGainsData = () => {
    hpLostData = [];
    xpGainData = [];
    for(let i = 0; i < data.length; i++){
      hpLostData.push(Math.floor(Math.random() * (metadata.maxHpLoss + 1)));
      xpGainData.push(Math.floor(Math.random() * (metadata.maxXpGain + 1)));
    }
    hpLostData.sort((a, b) => a-b);
    xpGainData.sort((a, b) => b-a);
    hpLostData[0] = 0;
    xpGainData[0] = metadata.maxXpGain;
    setFlag(flag + 1)
  }

  const setRankInput = (value, id) => {
    inputData[id] = value;
  }

  const setRank = (id, index) => {
    if(!parseFloat(inputData[id])){
      return;
    }
    const newData = cloneDeep(shuffledData);
    newData.splice(index,1);

    const newFinalData = cloneDeep(finalData);
    let newEntryRank = 0;
    for(let i = 0; i < finalData.length; i++){
      if(finalData[i].name === "-"){
        newEntryRank = i;
        break;
      }
    }

    const newEntryData = data.find(item => item.id === id);
    newFinalData[newEntryRank] = {
      ...newEntryData,
      score: parseFloat(inputData[id])
    };
    newFinalData.sort((a, b) => b.score - a.score);
    finalDataCount++;

    setShuffledData(newData);
    setFinalData(newFinalData);
  }

  const fillRandomly = () => {
    const newFinalData = cloneDeep(finalData);
    let shuffledDataIndex = 0;
    for(let i = 0; i < finalData.length; i++){
      if(finalData[i].name === "-"){
        newFinalData[i] = {
          ...shuffledData[shuffledDataIndex],
          score: Math.floor(Math.random() * 100000) / 1000
        };
        shuffledDataIndex++;
      }
    }
    newFinalData.sort((a, b) => b.score - a.score);
    setShuffledData([]);
    setFinalData(newFinalData);
    finalDataCount = finalData.length;
  }

  const sendData = () => {
    for(let i = 0; i < finalData.length; i++){
      dispatch(addResultsSheet({
        id: finalData[i].id,
        roundRank: i+1,
        data: {
          roundRank: i+1,
          score: finalData[i].score,
          totalPlayers: finalData.length,
          previousXp: Math.floor(finalData[i].totalXp),
          previousHp: finalData[i].currentHp,
          previousMaxHp: finalData[i].maxHp,
          previousLevel: finalData[i].level,
          previousLevelXp: Math.floor(finalData[i].xp),
          previousNextLevelXp: finalData[i].nextLevelXp,
        }
      }));
      dispatch(addXp({
        id: finalData[i].id,
        amount: xpGainData[i]
      }));
      dispatch(addHp({
        id: finalData[i].id,
        amount: hpLostData[i] * -1
      }));
      dispatch(addMedal({
        id: finalData[i].id,
        rank: i+1
      }));
      dispatch(updateStats(finalData[i].id));
    }
    dispatch(setRankPositions());
    navigate("/results");
  }

	return(
    <Grid container sx={{ margin: 3 }}>
      <Grid item xs={4} >
        <Grid container spacing={1}>
          {
            shuffledData.slice(0,10).map((item, i) => {
              const dataEntry = shuffledData[i];
              return (
                <>
                  <Grid item xs={5}>
                    <CompactUserTag
                      key={`${i}cot`} 
                      data={item} />
                  </Grid>
                  <Grid item xs={7}>
                    <AddToRankScore
                      index={i}
                      dataEntry={dataEntry}
                      onClick={setRank}
                      onChange={setRankInput}
                    />
                  </Grid>
                </>
              );
            })
          }
          <Button 
            disabled={finalDataCount !== data.length}
            variant="contained" 
            color="success" 
            onClick={sendData}
            sx={{ margin: 4 }} >
            Send Data
          </Button>
          <Button 
            disabled={finalDataCount === data.length}
            variant="contained" 
            color="error" 
            onClick={fillRandomly}
            sx={{ margin: 4 }} >
            Fill randomly
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={8}>
        <Grid container spacing={1}>
          {
            finalData.map((item, i) => {
              return (
                <Grid item>
                  <ScoreUserTag
                    key={`${2}cotrank`} 
                    data={item}
                    rank={i+1}
                    hpLoss={hpLostData ? hpLostData[i] : ""} 
                    xpGain={xpGainData ? xpGainData[i] : ""}  />
                </Grid>
              );
            })
          }
        </Grid>
      </Grid>
    </Grid>
	);
}	

const shuffle = (array) => {
  let currentIndex = array.length,  randomIndex;

  while (currentIndex != 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
}