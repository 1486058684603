import { TextField } from '@mui/material';
import React from 'react'

export const RankInput = (props) => {
  const {
    onChange,
    id,
    value,
    isScore
  } = props;

	return(
    <TextField
      autoComplete='off'
      focused
      value={value}
      id="outlined-basic" 
      label={isScore ? "Score" : "Rank"}
      variant="outlined" 
      color="whiteInput"
      sx={{ width: isScore ? 80 : 70, height:65, marginLeft: 2, marginRight: 2}}
      onChange={(event) => {
        onChange(event.target.value, id);
      }}
    />
	);
}	
