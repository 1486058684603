import { Avatar, Chip } from '@mui/material';
import React from 'react'

export const RankUserTag = (props) => {
  const {
    data,
    rank,
    hpLoss,
    xpGain
  } = props;

  const isInDanger = data.name !== "-" && data.currentHp <= 50;

	return(
    <>
      <Chip
        label={rank}
        color="white"
        sx={{ marginRight: 0.3, width: 46 }}
      />
      <Chip
        sx={{ width: 110 }}
        avatar={ data.name !== "-" && <Avatar
          src={data.image}
        />}
        variant={isInDanger ? "outlined" : "filled"}
        label={data.name}
        color={isInDanger ? "dangerRed" : data.color}
      />
      {
        (hpLoss != undefined && xpGain != undefined) && (
          <div>
            <Chip
              label={-hpLoss}
              color="hpRed"
              sx={{ marginRight: 0.3, height:15 }}
            />
            <Chip
              label={xpGain}
              color="xpBlue"
              sx={{ marginRight: 0.3, height:15 }}
            />
          </div>
        )}
    </>
	);
}	
