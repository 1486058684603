import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { CompactUserTag } from './CompactUserTag';
import { Button, Grid } from '@mui/material';
import { cloneDeep, set } from 'lodash';
import { RankUserTag } from './RankUserTag';
import { AddToRank } from './AddToRank';
import { addXp, addHp, setRankPositions, addResultsSheet, updateStats } from '../../slices/basicStatsSlice';
import { useNavigate } from 'react-router-dom';

const generateStartingFinalData = (data) => {
  let finalData = [];
  for(let i = 0; i < data.length; i++){
    finalData.push({
      name: "-",
      color: "grey"
    });
  }
  return finalData;
}

let inputData;
let hpLostData;
let xpGainData;
let finalDataCount;

export const PointsManager = (props) => {
	const data = useSelector(state => state.basicStats);
	const metadata = useSelector(state => state.metadata);
  const [shuffledData, setShuffledData] = useState(shuffle(cloneDeep(data)));
  const [finalData, setFinalData] = useState(generateStartingFinalData(data));
  const [flag, setFlag] = useState(1);
	const dispatch = useDispatch();
	const navigate = useNavigate();

  useEffect(() => {
    inputData = {};
    finalDataCount = 0;
    generateHpAndXpGainsData();
  },[]);

  const generateHpAndXpGainsData = () => {
    hpLostData = [];
    xpGainData = [];
    for(let i = 0; i < data.length; i++){
      hpLostData.push(Math.floor(Math.random() * (metadata.maxHpLoss + 1)));
      xpGainData.push(Math.floor(Math.random() * (metadata.maxXpGain + 1)));
    }
    hpLostData.sort((a, b) => a-b);
    xpGainData.sort((a, b) => a-b).reverse();
    hpLostData[0] = 0;
    xpGainData[0] = metadata.maxXpGain;
    setFlag(flag + 1)
  }

  const setRankInput = (value, id) => {
    inputData[id] = value;
  }

  const setRank = (id, index, rank) => {
    const newData = cloneDeep(shuffledData);
    newData.splice(index,1);

    const newFinalData = cloneDeep(finalData);
    const newEntryRank = rank ? rank : inputData[id];
    const newEntryData = data.find(item => item.id === id);
    if(newFinalData[newEntryRank - 1].name !== "-"){
      newData.unshift(newFinalData[newEntryRank - 1]);
      finalDataCount--;
    }
    finalDataCount++;
    newFinalData[newEntryRank - 1] = newEntryData;

    setShuffledData(newData);
    setFinalData(newFinalData);
  }

  const addToStart = (id, index, position) => {
    let rank = finalData.length;
    let startingPosition = 0;
    switch(position){
      case "midTop" : {
        startingPosition = Math.floor(finalData.length / 3);
        break;
      }
      case "midBottom" : {
        startingPosition = Math.floor(finalData.length / 3 * 2);
        break;
      }
    }
    for(let i = startingPosition; i < finalData.length; i++){
      if(finalData[i].name === "-"){
        rank = i+1;
        break;
      }
    }
    setRank(id, index, rank);
  }

  const addToEnd = (id, index) => {
    let rank = 1;
    for(let i = finalData.length - 1; i >= 0; i--){
      if(finalData[i].name === "-"){
        rank = i+1;
        break;
      }
    }
    setRank(id, index, rank);
  }

  const fillRandomly = () => {
    const newFinalData = cloneDeep(finalData);
    let shuffledDataIndex = 0;
    for(let i = 0; i < finalData.length; i++){
      if(finalData[i].name === "-"){
        newFinalData[i] = shuffledData[shuffledDataIndex];
        shuffledDataIndex++;
      }
    }
    setShuffledData([]);
    setFinalData(newFinalData);
    finalDataCount = finalData.length;
  }

  const sendData = () => {
    for(let i = 0; i < finalData.length; i++){
      dispatch(addResultsSheet({
        id: finalData[i].id,
        roundRank: i+1,
        data: {
          rank: i+1,
          totalPlayers: finalData.length,
          previousXp: Math.floor(finalData[i].totalXp),
          previousHp: finalData[i].currentHp,
          previousMaxHp: finalData[i].maxHp,
          previousLevel: finalData[i].level,
          previousLevelXp: Math.floor(finalData[i].xp),
          previousNextLevelXp: finalData[i].nextLevelXp,
        }
      }));
      dispatch(addXp({
        id: finalData[i].id,
        amount: xpGainData[i]
      }));
      dispatch(addHp({
        id: finalData[i].id,
        amount: hpLostData[i] * -1
      }));
      dispatch(updateStats(finalData[i].id));
    }
    dispatch(setRankPositions());
    navigate("/results");
  }

	return(
    <Grid container sx={{ padding: 3 }} >
      <Grid item xs={5} >
        <Grid container spacing={1}>
          {
            shuffledData.slice(0,10).map((item, i) => {
              const dataEntry = shuffledData[i];
              return (
                <>
                  <Grid item xs={4}>
                    <CompactUserTag
                      key={`${i}cot`} 
                      data={item} />
                  </Grid>
                  <Grid item xs={8}>
                    <AddToRank
                      index={i}
                      dataEntry={dataEntry}
                      onClick={setRank}
                      onAddToStartClick={addToStart}
                      onAddToEndClick={addToEnd}
                      onChange={setRankInput}
                    />
                  </Grid>
                </>
              );
            })
          }
          <Button 
            disabled={finalDataCount !== data.length}
            variant="contained" 
            color="success" 
            onClick={sendData}
            sx={{ margin: 4 }} >
            Send Data
          </Button>
          <Button 
            disabled={finalDataCount === data.length}
            variant="contained" 
            color="error" 
            onClick={fillRandomly}
            sx={{ margin: 4 }} >
            Fill randomly
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={7}>
        <Grid container spacing={1}>
          {
            finalData.map((item, i) => {
              return (
                <Grid item>
                  <RankUserTag
                    key={`${2}cotrank`} 
                    data={item}
                    rank={i+1}
                    hpLoss={hpLostData ? hpLostData[i] : ""} 
                    xpGain={xpGainData ? xpGainData[i] : ""}  />
                </Grid>
              );
            })
          }
        </Grid>
      </Grid>
    </Grid>
	);
}	

const shuffle = (array) => {
  let currentIndex = array.length,  randomIndex;

  while (currentIndex != 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
}