import { Button, Chip, Fab, Grid } from '@mui/material';
import React, { useState } from 'react'
import AddIcon from '@mui/icons-material/Add';
import { RankInput } from './RankInput';
import { AddButton } from './AddButton';

export const AddToRank = (props) => {
	const {
		index,
		dataEntry,
		onChange,
		onClick,
		onAddToStartClick,
		onAddToEndClick
	} = props;

	const [value, setValue] = useState("");

	return(
		<Grid container spacing={1}>
			<Grid item>
				<RankInput 
					value={value}
					key={`${index}ri`} 
					id={dataEntry.id} 
					onChange={(newValue, id) => {
						setValue(newValue);
						onChange(newValue, id);
					}} />
			</Grid>
			<Grid item>
				<AddButton 
					id={dataEntry.id} 
					onClick={(buttonId, buttonIndex) => {
						setValue("");
						onClick(buttonId, buttonIndex);
					}} 
					index={index} />
			</Grid>
			<Grid item xs={2}>
				<Grid container spacing={0.2}>
					<Grid item>
						<Chip 
							color="hpRed"
							size="small"
							label="Top"
							onClick={() => {
								setValue("");
								onAddToStartClick(dataEntry.id, index);
							}} />
					</Grid>
					<Grid item>
						<Chip 
							color="xpBlue"
							size="small"
							label="Bot"
							onClick={() => {
								setValue("");
								onAddToEndClick(dataEntry.id, index);
							}} />
					</Grid>
					<Grid item>
						<Chip 
							color="xpBlue"
							size="small"
							label="1/3"
							onClick={() => {
								setValue("");
								onAddToStartClick(dataEntry.id, index, "midTop");
							}} />
					</Grid>
					<Grid item>
						<Chip 
							color="hpRed"
							size="small"
							label="2/3"
							onClick={() => {
								setValue("");
								onAddToStartClick(dataEntry.id, index, "midBottom");
							}} />
					</Grid>		
				</Grid>
			</Grid>
		</Grid>
	);
}	
