import { Button, TextField } from '@mui/material';
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { addXp, healAll, setRankPositions } from '../../slices/basicStatsSlice';
import { useNavigate } from 'react-router-dom';

export const PhaseEnd = () => {
    const data = useSelector(state => state.basicStats);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [boostTop, setBoostTop] = useState("");
    const [boostValue, setBoostValue] = useState("");

    const healAndFinishPhase = () => {
        // for(let item of data){
        //     if(item.rank <= boostTop){
        //         dispatch(addXp({
        //             id: item.id,
        //             amount: boostValue
        //         }));
        //     }
        // }
        dispatch(healAll());
        // dispatch(setRankPositions());
        // navigate("/");
    };

	return(
        <>
            <TextField
                autoComplete='off'
                focused
                value={boostTop}
                id="outlined-basic" 
                label={"Boost Top"}
                variant="outlined" 
                color="whiteInput"
                sx={{ width: 100, height:65, margin: 3}}
                onChange={(event) => {
                    setBoostTop(event.target.value);
                }}
            />
            <TextField
                autoComplete='off'
                focused
                value={boostValue}
                id="outlined-basic" 
                label={"Boost Value"}
                variant="outlined" 
                color="whiteInput"
                sx={{ width: 100, height:65, margin: 3}}
                onChange={(event) => {
                    setBoostValue(event.target.value);
                }}
            />
            <Button 
                variant="contained" 
                color="success" 
                onClick={healAndFinishPhase}
                sx={{ margin: 4 }} >
                Heal and finish Phase
            </Button>
        </>
	);
}