import React from 'react'
import { StatProgressBar } from './StatProgressBar';

export const HpDisplay = (props) => {
	const {
    currentHp,
    maxHp
	} = props;

	return(
		<div className="levelDisplayContainer">
			<div className="levelDisplayLevel">
				{`HP`}
			</div>
			<StatProgressBar
				value={currentHp}
				max={maxHp} 
				color="hpRed" />
    </div>
	);
}	