import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { StatName } from '../../constants/stats';
import { addStatPointAgility, addStatPointAttack, addStatPointDefense, addStatPointHp, addStatPointPrecision, addStatPointSpeed, updateStats } from '../../slices/basicStatsSlice';
import { StatPointDistributionItem } from './StatPointDistributionItem';

export const StatPointDistributionCard = (props) => {
	const {
    data
	} = props;

	const {
		attack,
		defense,
		agility,
		speed,
		precision,
		hp
	} = data.statPoints;

	const dispatch = useDispatch();

	const getTotalPoints = () => {
		const level = data.level;
		return Math.floor(level ** 1.70 * 2 + 50);
	}

	const getUsedPoints = () => {
		return calculateBasicStatUsedPoints(attack) +
					 calculateBasicStatUsedPoints(defense) +
					 calculateBasicStatUsedPoints(agility) +
					 calculateBasicStatUsedPoints(speed) +
					 calculateBasicStatUsedPoints(precision) +
					 calculateHpUsedPoints(hp)
	}

	const getAvailablePoints = () => {
		return getTotalPoints() - getUsedPoints();
	}

	const calculateBasicStatPointsForNext = (tier) => { 
		return Math.floor(((tier) ** 1.4) + 5); 
	}

	const calculateBasicStatPointsOfPrevious = (tier) => { 
		if(tier === 0) {
			return 0;
		}
		return Math.floor((tier - 1 ** 1.4) + 5); 
	}

	const calculateHpPointsForNext = (tier) => {
		return calculateHpUsedPoints(tier + 1) - calculateHpUsedPoints(tier);
	}

	const calculateHpPointsOfPrevious = (tier) => { 
		if(tier === 0) {
			return 0;
		}
		return calculateHpUsedPoints(tier) - calculateHpUsedPoints(tier - 1);
	}

	const calculateBasicStatUsedPoints = (tier) => {
		let total = 0;
		for(let i = 0; i < tier; i++){
			total += Math.floor((i ** 1.4) + 5); 
		}
		return total;
	}

	const calculateHpUsedPoints = (tier) => {
		return Math.floor(calculateBasicStatUsedPoints(tier) / 3);
	}

	const onPlusClicked = (stat, value) => {
		const availablePoints = getAvailablePoints();
		const pointsForNext = stat === StatName.HP ? calculateHpPointsForNext(value) : calculateBasicStatPointsForNext(value);
		if(pointsForNext <= availablePoints) { 
			addToStatPoint(stat, 1);
		}
	};

	const onMinusClicked = (stat, value) => {
		if(value !== 0) {
			addToStatPoint(stat, -1);
		}
	};

	const addToStatPoint = (stat, amount) => {
		switch(stat){
			case StatName.ATTACK: {
				dispatch(addStatPointAttack({
					id: data.id,
					amount: amount
				}));
				break;
			}
			case StatName.DEFENSE: {
				dispatch(addStatPointDefense({
					id: data.id,
					amount: amount
				}));
				break;
			}
			case StatName.AGILITY: {
				dispatch(addStatPointAgility({
					id: data.id,
					amount: amount
				}));
				break;
			}
			case StatName.SPEED: {
				dispatch(addStatPointSpeed({
					id: data.id,
					amount: amount
				}));
				break;
			}
			case StatName.PRECISION: {
				dispatch(addStatPointPrecision({
					id: data.id,
					amount: amount
				}));
				break;
			}
			case StatName.HP: {
				dispatch(addStatPointHp({
					id: data.id,
					amount: amount
				}));
				break;
			}
		}
		dispatch(updateStats(data.id))
	}

	return(
		<div className="statPointDistributionCardContainer">
			<div>
				Available points: {/* Make component */}
			</div>
			<div>
				{getAvailablePoints()} 
			</div>
			<StatPointDistributionItem 
				name = "Attack"
				value = {attack}
				pointsOfPrevious = {calculateBasicStatPointsOfPrevious(attack)}
				pointsForNext = {calculateBasicStatPointsForNext(attack)}
				pointsSpent = {calculateBasicStatUsedPoints(attack)} 
				onPlusClicked = {() => onPlusClicked(StatName.ATTACK, attack)} 
				onMinusClicked = {() => onMinusClicked(StatName.ATTACK, attack)} />
			<StatPointDistributionItem 
				name = "Defense"
				value = {defense}
				pointsOfPrevious = {calculateBasicStatPointsOfPrevious(defense)}
				pointsForNext = {calculateBasicStatPointsForNext(defense)}
				pointsSpent = {calculateBasicStatUsedPoints(defense)} 
				onPlusClicked = {() => onPlusClicked(StatName.DEFENSE, defense)} 
				onMinusClicked = {() => onMinusClicked(StatName.DEFENSE, defense)} />
			<StatPointDistributionItem 
				name = "Agility"
				value = {agility}
				pointsOfPrevious = {calculateBasicStatPointsOfPrevious(agility)}
				pointsForNext = {calculateBasicStatPointsForNext(agility)}
				pointsSpent = {calculateBasicStatUsedPoints(agility)} 
				onPlusClicked = {() => onPlusClicked(StatName.AGILITY, agility)} 
				onMinusClicked = {() => onMinusClicked(StatName.AGILITY, agility)} />
			<StatPointDistributionItem 
				name = "Speed"
				value = {speed}
				pointsOfPrevious = {calculateBasicStatPointsOfPrevious(speed)}
				pointsForNext = {calculateBasicStatPointsForNext(speed)}
				pointsSpent = {calculateBasicStatUsedPoints(speed)} 
				onPlusClicked = {() => onPlusClicked(StatName.SPEED, speed)} 
				onMinusClicked = {() => onMinusClicked(StatName.SPEED, speed)} />
			<StatPointDistributionItem 
				name = "Precision"
				value = {precision}
				pointsOfPrevious = {calculateBasicStatPointsOfPrevious(precision)}
				pointsForNext = {calculateBasicStatPointsForNext(precision)}
				pointsSpent = {calculateBasicStatUsedPoints(precision)} 
				onPlusClicked = {() => onPlusClicked(StatName.PRECISION, precision)} 
				onMinusClicked = {() => onMinusClicked(StatName.PRECISION, precision)} />
			<StatPointDistributionItem 
				name = "HP"
				value = {hp}
				pointsOfPrevious = {calculateHpPointsOfPrevious(hp)}
				pointsForNext = {calculateHpPointsForNext(hp)}
				pointsSpent = {calculateHpUsedPoints(hp)} 
				onPlusClicked = {() => onPlusClicked(StatName.HP, hp)} 
				onMinusClicked = {() => onMinusClicked(StatName.HP, hp)} />
		</div>
	);
}	