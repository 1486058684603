import React from 'react'
import { HpXpMaxInput } from '../pointsManager/HpXpMaxInput';
import { Checkbox, Grid, MenuItem, Select, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setBronzeMedalTop, setBronzeMedalValue, setGoldMedalTop, setGoldMedalValue, setGreenMedalTop, setGreenMedalValue, setIsAlternativeImages, setMedalType, setSilverMedalTop, setSilverMedalValue } from '../../slices/basicStatsSlice';

export const PrizesScreen = () => {
    const metadata = useSelector(state => state.metadata);
	const dispatch = useDispatch();
	const greenMedalTop = metadata.greenMedalTop || 0;
	const greenMedalValue = metadata.greenMedalValue || 0;
	const goldMedalTop = metadata.goldMedalTop || 0;
	const goldMedalValue = metadata.goldMedalValue || 0;
	const silverMedalTop = metadata.silverMedalTop || 0;
	const silverMedalValue = metadata.silverMedalValue || 0;
	const bronzeMedalTop = metadata.bronzeMedalTop || 0;
	const bronzeMedalValue = metadata.bronzeMedalValue || 0;
	const medalType = metadata.medalType || "";

	return(
        <Grid container spacing={2} sx={{padding: 3}}>
            <HpXpMaxInput />
            <Grid item>
				<TextField
					autoComplete='off'
					focused
					size="small"
					value={greenMedalTop}
					label={"Green medal top"}
					id="outlined-basic" 
					variant="outlined" 
					color="whiteInput"
					sx={{ width: 120}}
					onChange={(event) => {
						dispatch(setGreenMedalTop(event.target.value));
					}}
				/>
			</Grid>
            <Grid item>
				<TextField
					autoComplete='off'
					focused
					size="small"
					value={greenMedalValue}
					label={"Green medal value"}
					id="outlined-basic" 
					variant="outlined" 
					color="whiteInput"
					sx={{ width: 130}}
					onChange={(event) => {
						dispatch(setGreenMedalValue(event.target.value));
					}}
				/>
			</Grid>
            <Grid item>
				<TextField
					autoComplete='off'
					focused
					size="small"
					value={goldMedalTop}
					label={"Gold medal top"}
					id="outlined-basic" 
					variant="outlined" 
					color="whiteInput"
					sx={{ width: 120}}
					onChange={(event) => {
						dispatch(setGoldMedalTop(event.target.value));
					}}
				/>
			</Grid>
            <Grid item>
				<TextField
					autoComplete='off'
					focused
					size="small"
					value={goldMedalValue}
					label={"Gold medal value"}
					id="outlined-basic" 
					variant="outlined" 
					color="whiteInput"
					sx={{ width: 130}}
					onChange={(event) => {
						dispatch(setGoldMedalValue(event.target.value));
					}}
				/>
			</Grid>
            <Grid item>
				<TextField
					autoComplete='off'
					focused
					size="small"
					value={silverMedalTop}
					label={"Silver medal top"}
					id="outlined-basic" 
					variant="outlined" 
					color="whiteInput"
					sx={{ width: 120}}
					onChange={(event) => {
						dispatch(setSilverMedalTop(event.target.value));
					}}
				/>
			</Grid>
            <Grid item>
				<TextField
					autoComplete='off'
					focused
					size="small"
					value={silverMedalValue}
					label={"Silver medal value"}
					id="outlined-basic" 
					variant="outlined" 
					color="whiteInput"
					sx={{ width: 130}}
					onChange={(event) => {
						dispatch(setSilverMedalValue(event.target.value));
					}}
				/>
			</Grid>
            <Grid item>
				<TextField
					autoComplete='off'
					focused
					size="small"
					value={bronzeMedalTop}
					label={"Bronze medal top"}
					id="outlined-basic" 
					variant="outlined" 
					color="whiteInput"
					sx={{ width: 130}}
					onChange={(event) => {
						dispatch(setBronzeMedalTop(event.target.value));
					}}
				/>
			</Grid>
            <Grid item>
				<TextField
					autoComplete='off'
					focused
					size="small"
					value={bronzeMedalValue}
					label={"Bronze medal value"}
					id="outlined-basic" 
					variant="outlined" 
					color="whiteInput"
					sx={{ width: 140}}
					onChange={(event) => {
						dispatch(setBronzeMedalValue(event.target.value));
					}}
				/>
			</Grid>
            <Grid item>
                <Select
                    value={medalType}
                    focused
                    label="Medal Type"
                    sx={{ width: 140 }}
                    color="whiteInput"
                    onChange={(event) => {
						dispatch(setMedalType(event.target.value));
					}}
                >
                    <MenuItem value={"xpBoost"}>XP Boost</MenuItem>
                    <MenuItem value={"hpBoost"}>Extra HP</MenuItem>
                </Select>
			</Grid>
			<Grid item>
				<Checkbox 
					label="Alternative Images"
					color="whiteInput"
					checked={metadata.isAlternativeImages || false}
					onChange={(event) => {
						dispatch(setIsAlternativeImages(event.target.checked));
					}} />
			</Grid>
        </Grid>
	);
}