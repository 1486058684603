import { Button, Chip, Fab, Grid } from '@mui/material';
import React, { useState } from 'react'
import AddIcon from '@mui/icons-material/Add';
import { RankInput } from './RankInput';
import { AddButton } from './AddButton';

export const AddToRankScore = (props) => {
	const {
		index,
		dataEntry,
		onChange,
		onClick
	} = props;

	const [value, setValue] = useState("");

	return(
		<Grid container spacing={1} sx={{paddingBottom: 2}}>
			<Grid item>
				<RankInput 
					isScore
					value={value}
					key={`${index}ri`} 
					id={dataEntry.id} 
					onChange={(newValue, id) => { //TODO Same as below, no need to send the data
						setValue(newValue);
						onChange(newValue, id);
					}} />
			</Grid>
			<Grid item>
				<AddButton 
					id={dataEntry.id} 
					onClick={(buttonId, buttonIndex) => { //TODO No need, can make it independent and get id and index from here
						onClick(buttonId, buttonIndex); //TODO Handle click id here not in dictionary
						setValue("");
					}} 
					index={index} />
			</Grid>
			<Grid item xs={12}>
				<Grid container spacing={0.5}>
					<Grid item>
						<Chip 
							color="xpBlue"
							label="97"
							onClick={() => {
								onChange(Math.floor(Math.random() * 750 + 97000) / 1000, dataEntry.id);
								onClick(dataEntry.id, index);
								setValue("");
							}} />
					</Grid>
					<Grid item>
						<Chip 
							color="xpBlue"
							label="96"
							onClick={() => {
								onChange(Math.floor(Math.random() * 1000 + 96000) / 1000, dataEntry.id);
								onClick(dataEntry.id, index);
								setValue("");
							}} />
					</Grid>
					<Grid item>
						<Chip 
							color="xpBlue"
							label="90"
							onClick={() => {
								onChange(Math.floor(Math.random() * 6000 + 90000) / 1000, dataEntry.id);
								onClick(dataEntry.id, index);
								setValue("");
							}} />
					</Grid>
					<Grid item>
						<Chip 
							color="hpRed"
							label="85"
							onClick={() => {
								onChange(Math.floor(Math.random() * 5000 + 85000) / 1000, dataEntry.id);
								onClick(dataEntry.id, index);
								setValue("");
							}} />
					</Grid>
					<Grid item>
						<Chip 
							color="xpBlue"
							label="80"
							onClick={() => {
								onChange(Math.floor(Math.random() * 5000 + 80000) / 1000, dataEntry.id);
								onClick(dataEntry.id, index);
								setValue("");
							}} />
					</Grid>
					<Grid item>
						<Chip 
							color="hpRed"
							label="75"
							onClick={() => {
								onChange(Math.floor(Math.random() * 5000 + 75000) / 1000, dataEntry.id);
								onClick(dataEntry.id, index);
								setValue("");
							}} />
					</Grid>	
					<Grid item>
						<Chip 
							color="xpBlue"
							label="70"
							onClick={() => {
								onChange(Math.floor(Math.random() * 5000 + 70000) / 1000, dataEntry.id);
								onClick(dataEntry.id, index);
								setValue("");
							}} />
					</Grid>	
					<Grid item>
						<Chip 
							color="hpRed"
							label="65"
							onClick={() => {
								onChange(Math.floor(Math.random() * 5000 + 65000) / 1000, dataEntry.id);
								onClick(dataEntry.id, index);
								setValue("");
							}} />
					</Grid>	
					<Grid item>
						<Chip 
							color="xpBlue"
							label="60"
							onClick={() => {
								onChange(Math.floor(Math.random() * 5000 + 60000) / 1000, dataEntry.id);
								onClick(dataEntry.id, index);
								setValue("");
							}} />
					</Grid>	
					<Grid item>
						<Chip 
							color="hpRed"
							label="0"
							onClick={() => {
								onChange(Math.floor(Math.random() * 55000) / 1000, dataEntry.id);
								onClick(dataEntry.id, index);
								setValue("");
							}} />
					</Grid>	
				</Grid>
			</Grid>
		</Grid>
	);
}	
