import React from 'react'
import { StatProgressBar } from './StatProgressBar';

export const LevelDisplay = (props) => {
	const {
		level,
		xp,
		nextLevelXp
	} = props;

	return(
		<div className="levelDisplayContainer">
			<div className="levelDisplayLevel">
				{`Lv ${level}`}
			</div>
			<StatProgressBar
				value={Math.floor(xp)}
				max={nextLevelXp}
				color="blue" />
    </div>
	);
}	