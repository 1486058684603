import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { BasicDataRow } from './BasicDataRow';
import { SortableDataHeader as BasicDataHeader } from './BasicDataHeader';
import { cloneDeep } from 'lodash';
import { BasicDataFilter } from './BasicDataFilter';
import { ClassName } from '../../constants/classes';

const MULTISORT_KEY = "Shift";

export const SortingDirection = { //TODO Separate file
	NONE: "none",
	DESCENDING: "descending",
	ASCENDING: "ascending"
};

const KeyToAttribute = {
	0: "rank",
	1: "currentHp",
	2: "totalXp",
	3: "attack",
	4: "defense",
	5: "speed",
	6: "agility",
	7: "precision",
	8: "team",
	9: "medalScore",
};

export const BasicDataGrid = (props) => {
	// generatePlayersJSON();
	const data = useSelector(state => state.basicStats);
	const [sortedData, setSortedData] = useState(cloneDeep(data));
	const [sortOn, setSortOn] = useState([]);
	const [isMultisortKeyPressed, setIsMultisortKeyPressed] = useState(false);
	const dispatch = useDispatch()
	
	useEffect(() => {
		const sortedDataClone = cloneDeep(sortedData); //TODO Less cloning xD?
		sortedDataClone.sort((itemA, itemB) => {
			let order = 0;
			for(const criteria of sortOn){
				if(order !== 0){
					break;
				}
				const itemAValue = itemA[KeyToAttribute[criteria.id]] || "000";
				const itemBAValue = itemB[KeyToAttribute[criteria.id]] || "000";

				if(itemAValue > itemBAValue){
					order = criteria.descending ? -1 : 1;
				}else if(itemAValue < itemBAValue){
					order = criteria.descending ? 1 : -1;
				}else{
					order = 0;
				}
			}
			return order;
		});
		setSortedData(sortedDataClone);

		const keyDownHandler = event => {
      if (event.key === MULTISORT_KEY) {
      	setIsMultisortKeyPressed(true);
      }
    };
		const keyUpHandler = event => {
      if (event.key === MULTISORT_KEY) {
      	setIsMultisortKeyPressed(false);
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    document.addEventListener('keyup', keyUpHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
      document.removeEventListener('keyup', keyUpHandler);
    };


	}, [sortOn]);
	
	const getSortingDirection = (id) => {
		const sortOnItem = sortOn.find(item => item.id === id);
		if(!sortOnItem){
			return SortingDirection.NONE;
		}else if(sortOnItem.descending){
			return SortingDirection.DESCENDING;
		}
		return SortingDirection.ASCENDING;
	}

	const addSortOn = (id) => {
		let sortOnClone = cloneDeep(sortOn);
		const sortOnKey = sortOnClone.find(item => item.id === id);
		if(!sortOnKey) {
			if(isMultisortKeyPressed){
				sortOnClone.push({
					id,
					descending: true
				});
			}else{
				sortOnClone = [{
					id,
					descending: true
				}];
			}
		}else if(sortOnKey.descending){
			sortOnKey.descending = false;
		}else {
			const sortOnKeyIndex = sortOnClone.findIndex(item => item.id === id);
			sortOnClone.splice(sortOnKeyIndex, 1); //TODO Redundant?
		}
		setSortOn(sortOnClone);
	}

	return(
    <div className="basicDataGridContainer">
			{/* <BasicDataFilter /> */}
			<BasicDataHeader
				value="Rank" 
				sortingDirection={getSortingDirection(0)} 
				onClick={() => addSortOn(0)} />
			<BasicDataHeader
				value="Card" 
				sortingDirection={getSortingDirection(8)}
				onClick={() => addSortOn(8)} />
			<BasicDataHeader 
				value="HP" 
				sortingDirection={getSortingDirection(1)}
				onClick={() => addSortOn(1)} />
			<BasicDataHeader 
				value="Level" 
				sortingDirection={getSortingDirection(2)}
				onClick={() => addSortOn(2)} />
			<BasicDataHeader 
				value="Medals" 
				sortingDirection={getSortingDirection(9)}
				onClick={() => addSortOn(9)} />
			<BasicDataHeader 
				value="Attack" 
				sortingDirection={getSortingDirection(3)} 
				onClick={() => addSortOn(3)} />
			<BasicDataHeader 
				value="Defense" 
				sortingDirection={getSortingDirection(4)} 
				onClick={() => addSortOn(4)} />
			<BasicDataHeader 
				value="Speed" 
				sortingDirection={getSortingDirection(5)} 
				onClick={() => addSortOn(5)} />
			<BasicDataHeader 
				value="Agility" 
				sortingDirection={getSortingDirection(6)} 
				onClick={() => addSortOn(6)} />
			<BasicDataHeader 
				value="Precision" 
				sortingDirection={getSortingDirection(7)} 
				onClick={() => addSortOn(7)} />
			{
				sortedData.map((item, i) => {
					return <BasicDataRow
								data={item} 
								key={i} />;
				})
			}
    </div>
	);
}


const generatePlayersJSON = () => {
	const completePlayers = [];
	const playerBasics = [
	  {name: "Elyria", color: "purple"},
	  {name: "Alice", color: "yellow"},
	  {name: "Vermouth", color: "black"},
	  {name: "Christa", color: "red"},
	  {name: "Aria", color: "black"},
	  {name: "Anastasia", color: "blue"},
	  {name: "2B", color: "black"},
	  {name: "Texas", color: "black"},
	  {name: "Ayaka", color: "white"},
	  {name: "Nia", color: "yellow"},
	  {name: "Holo", color: "brown"},
	  {name: "Zelda", color: "blue"},
	  {name: "Haru", color: "purple"},
	  {name: "Kasumi", color: "red"},
	  {name: "Makoto", color: "black"},
	  {name: "Joker", color: "black"},
	  {name: "Nilou", color: "blue"},
	  {name: "Mona", color: "blue"},
	  {name: "Tifa", color: "black"},
	  {name: "Asuka", color: "red"},
	  {name: "Ganyu", color: "purple"},
	  {name: "Kequing", color: "purple"},
	  {name: "Albedo", color: "yellow"},
	  {name: "Marine", color: "red"},
	  {name: "Link", color: "blue"},
	  {name: "Nanami", color: "pink"},
	  {name: "Pneuma", color: "green"},
	  {name: "Pyra", color: "red"},
	  {name: "Mythra", color: "yellow"},
	  {name: "Kaido", color: "black"},
	  {name: "Madoka", color: "brown"},
	  {name: "Kuzan", color: "white"},
	  {name: "Borsalino", color: "yellow"},
	  {name: "Sakazuki", color: "red"},
	  {name: "Rayleigh", color: "white"},
	  {name: "Shanks", color: "red"},
	  {name: "Shinichi", color: "black"},
	  {name: "Sera", color: "black"},
	  {name: "Miku", color: "blue"},
	  {name: "Mark", color: "orange"},
	  {name: "Axel", color: "white"},
	  {name: "Rococo", color: "blue"},
	  {name: "Aphrodite", color: "white"},
	  {name: "Lumine", color: "yellow"},
	  {name: "Lapis", color: "red"},
	  {name: "Diamant", color: "red"},
	  {name: "Timerra", color: "pink"},
	  {name: "Fogado", color: "yellow"},
	  {name: "Veyle", color: "black"},
	  {name: "Edelgard", color: "red"},
	  {name: "Dorothea", color: "brown"},
	  {name: "Dimitri", color: "blue"},
	  {name: "Mercedes", color: "yellow"},
	  {name: "Ingrid", color: "yellow"},
	  {name: "Lysithea", color: "white"},
	  {name: "Hilda", color: "pink"},
	  {name: "Leonie", color: "orange"},
	  {name: "Flayn", color: "green"},
	  {name: "Catherine", color: "yellow"},
	  {name: "Shamir", color: "blue"},
	  {name: "Anna", color: "purple"},
	  {name: "Sothis", color: "green"},
	  {name: "Rhea", color: "green"},
	  {name: "Candace", color: "purple"},
	  {name: "Cyno", color: "white"},
	  {name: "Yelan", color: "purple"},
	  {name: "Yae Miko", color: "pink"},
	  {name: "Yun Jin", color: "purple"},
	  {name: "Gorou", color: "brown"},
	  {name: "Thoma", color: "yellow"},
	  {name: "Kokomi", color: "blue"},
	  {name: "Shogun", color: "purple"},
	  {name: "Yoimiya", color: "yellow"},
	  {name: "Eula", color: "blue"},
	  {name: "Yanfei", color: "red"},
	  {name: "Hu Tao", color: "red"},
	  {name: "Xiao", color: "black"},
	  {name: "Zhongly", color: "brown"},
	  {name: "Tartaglia", color: "yellow"},
	  {name: "Noelle", color: "white"},
	  {name: "Fischl", color: "purple"},
	  {name: "Venti", color: "green"},
	  {name: "Diluc", color: "red"},
	  {name: "Barbara", color: "white"},
	  {name: "Jean", color: "yellow"},
	  {name: "Enterprise", color: "white"},
	  {name: "Bagpipe", color: "orange"},
	  {name: "Whislash", color: "yellow"},
	  {name: "Yato", color: "brown"},
	  {name: "Akafuyu", color: "purple"},
	  {name: "Breeze", color: "purple"},
	  {name: "Durin", color: "black"},
	  {name: "Exusiai", color: "red"},
	  {name: "Haze", color: "black"},
	  {name: "Jackie", color: "black"},
	  {name: "Melantha", color: "brown"},
	  {name: "Mostima", color: "blue"},
	  {name: "Amiya", color: "blue"},
	  {name: "Rope", color: "black"},
	  {name: "Bibeak", color: "white"},
	  {name: "Ambriel", color: "pink"},
	  {name: "Blaze", color: "black"},
	  {name: "Blemishine", color: "yellow"},
	  {name: "Cardigan", color: "white"},
	  {name: "Ceobe", color: "red"},
	  {name: "Goldenglow", color: "pink"},
	  {name: "Franka", color: "brown"},
	  {name: "Dusk", color: "black"},
	  {name: "Ichinose", color: "brown"},
	  {name: "Kakudate", color: "brown"},
	  {name: "Riza", color: "red"},
	  {name: "Kurodate", color: "white"},
	  {name: "Ajitani", color: "yellow"},
	  {name: "Shiromi", color: "white"},
	  {name: "Juri", color: "red"},
	  {name: "Toki", color: "blue"},
	  {name: "Rin", color: "red"},
	  {name: "Lady Avalon", color: "pink"},
	  {name: "Okita", color: "blue"},
	  {name: "Morgan", color: "black"},
	  {name: "Galatea", color: "white"},
	  {name: "Artoria", color: "yellow"},
	  {name: "Shounagon", color: "black"},
	  {name: "Yang", color: "black"},
	  {name: "Astolfo", color: "pink"},
	  {name: "Nobunaga", color: "red"},
	  {name: "Jeanne", color: "black"},
	  {name: "Meltryllis", color: "purple"},
	  {name: "Ranmaru", color: "blue"},
	  {name: "Robin", color: "black"},
	  {name: "Nami", color: "orange"},
	  {name: "Oden", color: "black"},
	  {name: "Ai", color: "blue"},
	  {name: "Shenhe", color: "pink"},
	  {name: "Asuna", color: "blue"},
	  {name: "Baltimore", color: "blue"},
	  {name: "Chika", color: "white"},
	  {name: "Haguro", color: "black"},
	  {name: "Implacable", color: "brown"},
	  {name: "Jahy", color: "black"},
	  {name: "Karin", color: "yellow"},
	  {name: "Makima", color: "brown"},
	  {name: "Marcia", color: "black"},
	  {name: "Noa", color: "white"},
	  {name: "Ragensburg", color: "green"},
	  {name: "Sage", color: "yellow"},
	  {name: "Saori", color: "purple"},
	  {name: "Suisei", color: "blue"},
	  {name: "Belfast", color: "white"},
	  {name: "Nikke", color: "pink"},
	];

	for(let i = 0; i < playerBasics.length; i++){
	  completePlayers.push({
		id: i,
		name: playerBasics[i].name,
		color: playerBasics[i].color,
		image: `${process.env.PUBLIC_URL}/img/${playerBasics[i].name}.jpg`,
		level: 1,
		xp: 0,
		nextLevelXp: 10,
		totalXp: 0,
		attack: 8,
		defense: 8,
		agility: 8,
		speed: 8,
		precision: 8,
		hpRegeneration: 2,
		maxHp: 250,
		currentHp: 250,
		lastRoundRank: 0,
		class: ClassName.NOVICE,
		lastRank: 1500,
		rank: 1,
		statPoints: {
		  attack: 0,
		  defense: 0,
		  agility: 0,
		  speed: 0,
		  precision: 0,
		  hp: 0
		},
		results: []
	  });
	}
  }