import React from 'react'
import { useSelector } from 'react-redux';
import { CompactUserTag } from '../pointsManager/CompactUserTag';
import { Grid } from '@mui/material';
import { UserResultsCard } from './UserResultsCard';
import { cloneDeep } from 'lodash';

export const ResultsOverview = (props) => {
	const data = useSelector(state => state.basicStats);
	
	const eliminations = useSelector(state => state.eliminations);
	const filteredEliminations = cloneDeep(eliminations).filter((item) => {
		return item.results.length === data[0].results.length;
	});
	const sortedData = cloneDeep(data.concat(filteredEliminations)).sort((a,b) => a.lastRoundRank - b.lastRoundRank);

	return(
		<Grid container spacing={10} sx={{ padding: 3 }}>
			{
				sortedData.map((item, i) => {
					return(
						<Grid item>
							<UserResultsCard
								dkey={`${i}urc`} 
								data={item} />
						</Grid>
					);
				})
			}
		</Grid>
	);
}	